import {
  Button,
  Enfatize,
  Image,
  NavigationWrapper,
  PickerHOC,
  TargetsUtilsWrapper,
  TargetsWrapper,
  Text,
  UiWrapper,
  UserWrapper,
  View,
} from '@coinscrap/webapp-core';
import React, { useEffect } from 'react';

// components
import Footer from 'common/Footer';
import Header from 'common/Header';
import HeaderRedPrimary from 'common/HeaderRedPrimary';
import { LoadingData } from 'common/LoadingData';

// images
import congratulations from 'assets/images/congratulations.png';
import BgFinish from '../../../assets/images/BgFinish.png';

// configs

import colors from 'config/colors';
import routes from 'config/routes';

export default PickerHOC(
  {
    user: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },
    target: {
      arrayItemMatcher: {
        type: 'param',
        itemPropertyPath: 'id',
        paramName: 'idTarget',
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
  },
  LoadingData,
)(() => {
  const { target, user } = PickerHOC.use();

  const { useHeader, useFooter, useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const { activate } = TargetsUtilsWrapper.use();
  console.log({ user });

  useLoading(false);
  useEffect(() => {
    if (target.status === 'TEMPORARY') {
      activate(target.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useHeader(
    <View>
      <HeaderRedPrimary />
      <Header center="CONFIG. BANCO." left="atras" />
    </View>,
    [],
  );
  useFooter(
    <Footer style={{ backgroundColor: colors.white, marginBottom: 20 }}>
      <Button
        disabled={target.status !== 'ACTIVE'}
        borderWhiteButtonSt
        onClick={() => navigateTo(routes.home)}
      >
        ¡Yujuu!
      </Button>
    </Footer>,
    [target],
  );

  return target.status === 'ACTIVE' ? (
    <>
      <View
        fullHeightSt
        spaceBetweenSt
        paddingMainSt
        style={{
          backgroundImage: `url(${BgFinish})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Text style={{ fontSize: 25, marginTop: 40 }}>¡Enhorabuena {user?.name}!</Text>
        <View style={{ width: 190, position: 'relative', right: 15 }}>
          <Image style={{ width: '100%', height: 'auto' }} src={congratulations} />
        </View>
        <View style={{ width: '80%' }}>
          <Text style={{ fontSize: 15, marginTop: 30, textAlign: 'center' }}>
            <Enfatize style={{ color: colors.red }}>
              Has finalizado con éxito la creación de tu objetivo.
            </Enfatize>
            {` Haz click para continuar.`}
          </Text>
        </View>
      </View>
    </>
  ) : (
    <View fullHeightSt style={{ maxWidth: '300px' }}>
      <Text
        style={{
          fontSize: 28,
          lineHeight: '32px',
          textAlign: 'center',
          marginBottom: 20,
        }}
      >
        Tu reto está empezando
      </Text>

      <Text style={{ fontSize: 16, lineHeight: '20px', textAlign: 'center' }}>
        Este proceso puede llevar unos minutos.
      </Text>
    </View>
  );
});
